import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { logout } from "../../redux/action";

export const AdminPrivateRoute = ({
  component: Comp,
  path,
  redirectedPath = "/admin",
  ...rest
}) => {
  const dispatch = useDispatch();
  const { location } = useHistory();

  const { token, isLoggedIn, user } = useSelector((state) => {
    const { token, isLoggedIn, user } = state.userData;

    return { token, isLoggedIn, user };
  });

  useEffect(() => {
    if (!user.isAdmin) {
      dispatch(logout());
    }
  }, [user]);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return token && isLoggedIn && user.isAdmin ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectedPath,
              state: {
                prevLocation: location.pathname,
                error: "Unauthorized Access!",
              },
            }}
          />
        );
      }}
    />
  );
};
