import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "../components/PrivateRoute";
import Admin from "./Admin";
import Home from "./Auth/Home";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Dashboard from "./Dashboard";
import Home1 from "./Auth/Home1";
import Home2 from "./Auth/Home2";
import Home3 from "./Auth/Home3";

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home1} />
      <Route exact path="/home2" component={Home2} />
      <Route exact path="/home3" component={Home3} />
      <Route exact path="/signin" component={Login} />
      <Route exact path="/signup" component={Register} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route path="/admin" component={Admin} />
    </Switch>
  );
};

export default Pages;
