import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { FormError } from "../../../components/Error";
import { GoBack } from "../../../components/Layout/GoBack";
import {
  updatePasswordRequest,
  clearUpdatePasswordSuccess,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BottomNav } from "../../../components/Nav/BottomNav";

const Security = () => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    user,
    updatePasswordError,
    updatePasswordLoading,
    updatePasswordSuccess,
    token,
  } = useSelector((state) => {
    const {
      success: { updatePassword: updatePasswordSuccess },
      errors: { updatePassword: updatePasswordError },
    } = state.ajaxStatuses;

    const { updatePasswordLoading } = state.loadingIndicator;

    const { user, token } = state.userData;

    return {
      user,
      updatePasswordError,
      updatePasswordLoading,
      updatePasswordSuccess,
      token,
    };
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: yup.object({
      password: yup.string().required("Password is required"),
      newPassword: yup.string().required("New Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.newPassword !== confirmPassword) {
        toast.error("Password does not match", {
          duration: 3000,
        });
      } else {
        const formData = prop;
        console.log(formData);
        dispatch(updatePasswordRequest({ formData, token }));
      }
    },
  });

  useEffect(() => {
    if (updatePasswordError) {
      toast.error(updatePasswordError, {
        duration: 3000,
      });
    }
  }, [updatePasswordError]);

  useEffect(() => {
    if (updatePasswordSuccess) {
      toast.success(updatePasswordSuccess, {
        duration: 3000,
      });

      history.push("/signin");

      dispatch(clearUpdatePasswordSuccess());
    }
  }, [updatePasswordSuccess]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen overflow-y-scroll">
        <GoBack />
        <h2 className="font-body font-bold text-xl mt-3">Change Password</h2>

        <form className="max-w-xs mx-auto mt-10">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Old Password</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"password"}
                {...formik.getFieldProps("password")}
                placeholder="Your old password"
                required
              />
              {formik.errors.password && formik.touched.password && (
                <FormError message={formik.errors.password} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">New Password</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type="password"
                {...formik.getFieldProps("newPassword")}
                placeholder="Your new password"
                required
              />
              {formik.errors.newPassword && formik.touched.newPassword && (
                <FormError message={formik.errors.newPassword} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Confirm New Password</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
              />
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-y-6">
            <PrimaryButton
              text={"Update"}
              action={formik.handleSubmit}
              loading={updatePasswordLoading}
              disabled={!formik.isValid || confirmPassword === ""}
            />
          </div>
        </form>
      </div>

      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Security;
