import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { FormError } from "../../../components/Error";
import { GoBack } from "../../../components/Layout/GoBack";
import {
  clearUpdateUserSuccess,
  getLogUserRequest,
  updateUserRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BottomNav } from "../../../components/Nav/BottomNav";

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, updateUserError, updateUserSuccess, updateUserLoading, token } =
    useSelector((state) => {
      const {
        success: { updateUser: updateUserSuccess },
        errors: { updateUser: updateUserError },
      } = state.ajaxStatuses;

      const { updateUserLoading } = state.loadingIndicator;

      const { user, token } = state.userData;

      return {
        user,
        updateUserError,
        updateUserSuccess,
        updateUserLoading,
        token,
      };
    });

  const formik = useFormik({
    initialValues: {
      userName: "",
      phone: "",
      walletAddress: "",
    },
    validationSchema: yup.object({
      userName: yup
        .string()
        .required("User name is required")
        .max(32, "User name is too long")
        .trim()
        .required("Username is required"),
      phone: yup.string().required("Phone is required"),
      walletAddress: yup.string().required("walletAddress is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      const id = user._id;
      console.log(prop);

      dispatch(updateUserRequest({ formData, id }));
    },
  });

  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toast.success(updateUserSuccess, {
        duration: 3000,
      });

      dispatch(clearUpdateUserSuccess());
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    dispatch(getLogUserRequest(token));
  }, [getLogUserRequest]);

  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        userName: user.userName,
        phone: user.phone,
        walletAddress: user.walletAddress,
      }));
    }
  }, [user]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen overflow-y-scroll">
        <GoBack />
        <h2 className="font-fontBold text-xl mt-3">My Profile</h2>

        <form className="max-w-xs mx-auto mt-10">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">User Name</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"text"}
                {...formik.getFieldProps("userName")}
                placeholder="Your username"
                required
              />
              {formik.errors.userName && formik.touched.userName && (
                <FormError message={formik.errors.userName} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Phone</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type="number"
                {...formik.getFieldProps("phone")}
                placeholder="Your phone number"
                required
              />
              {formik.errors.phone && formik.touched.phone && (
                <FormError message={formik.errors.phone} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Wallet Address</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"text"}
                {...formik.getFieldProps("walletAddress")}
                placeholder="Your wallet address"
                required
              />
              {formik.errors.walletAddress && formik.touched.walletAddress && (
                <FormError message={formik.errors.walletAddress} />
              )}
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-y-6">
            <PrimaryButton
              text={"Update"}
              action={formik.handleSubmit}
              loading={updateUserLoading}
              disabled={!formik.isValid}
            />
          </div>
        </form>
      </div>

      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Profile;
