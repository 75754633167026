import { DeleteUser } from "./DeleteUser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { format } from "date-fns";

export const UsersCard = ({ user }) => {
  return (
    <div className="rounded-lg shadow mx-2 flex justify-between p-2 gap-x-4 ">
      <div className="w-20">
        <h3 className="text-sm">{user.userName && user.userName}</h3>
        <p className="text-xs">{user.email && user.email}</p>
      </div>
      <div className="ml-auto">
        <p className="text-sm">
          {user.createdAt && format(Date.parse(user.createdAt), "MM/dd/yyyy")}
        </p>
      </div>
      {/* <div>
          <div className="text-xs border-2 border-primary rounded-md px-4">
            View
          </div>
        </div> */}
      <div className="flex gap-x-8 justify-center ml-auto">
        <Link
          to={`/admin/user/${user._id}`}
          className="text-blue-500 hover:text-blue-600 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </Link>
        <DeleteUser user={user} />
      </div>
    </div>
  );
};
