import React, { useState } from "react";
import { PrimaryButton } from "../buttons";
import { motion } from "framer-motion";
import { FormError } from "../Error";

export const Page1 = ({
  handleClose,
  pages,
  setPages,
  x,
  setX,
  selectedToken,
  setSelectedToken,
  amount,
  setAmount,
  formik,
}) => {
  const [toggleSelectState, setToggleSelectState] = useState(false);

  const [error, setError] = useState("");

  const tokensArray = [
    {
      tokenName: "BTC",
      logopath: "/img/icons/btc.svg",
      address: "bc1qlsxrhyqum6kekls3yyfs8xmpfa97xphtpvya6z",
      network: "bitcoin",
    },
    {
      tokenName: "USDT TRC20",
      logopath: "/img/icons/tether.svg",
      address: "TFsBGyGHfFaVPth31T5ztxQc4sentFGkhG",
      network: "Tron",
    },
    {
      tokenName: "Ethereum",
      logopath: "/img/icons/eth.svg",
      address: "0xd808821c33A4ace7e63D61A1DC965125BaCf1a3f",
      network: "Ethereum",
    },
    // {
    //   tokenName: "USDT ERC20 ",
    //   logopath: "/img/icons/tether.svg",
    //   address: "0x2469eEC9544CE9D723c03f8aA256091D6c0ab7E1",
    //   network: "BSC",
    // },
  ];

  const setToken = (token) => {
    setSelectedToken({
      ...selectedToken,
      tokenName: token.tokenName,
      logopath: token.logopath,
      address: token.address,
      network: token.network,
    });

    setToggleSelectState(!toggleSelectState);
  };
  return (
    <motion.div
      key={"courseTrack"}
      initial={{ x: 0 }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}
      exit={{ x: x }}
      className="container mx-auto max-w-sm pt-14 px-4 "
    >
      <div className="flex justify-between px-3">
        <img
          onClick={handleClose}
          className="cursor-pointer"
          src="/img/icons/arrowleft.svg"
        />
        <p className="text-sm font-body">
          1 <span className="text-fadedGray">of 2</span>
        </p>
        <p
          onClick={handleClose}
          className="text-red-500 font-body text-sm cursor-pointer text-right"
        >
          Cancel
        </p>
      </div>

      <div className="pt-4">
        <h2 className="text-xl font-body font-bold ">Deposit</h2>
        <h2 className="text-base font-body font-medium text-center mt-8">
          Crypto
        </h2>
        <img
          className="text-center mx-auto mt-2"
          src="/img/icons/ellipse.svg"
        />
      </div>

      <form className="max-w-xs mx-auto mt-8">
        <div className="flex flex-col gap-y-4 transition-all duration-500">
          <div className="flex flex-col gap-y-2 relative">
            <label className="text-xs font-body">Select Crypto</label>
            <div className="border-b-2 bg-white w-full  px-2 ">
              {selectedToken.tokenName === "" ? (
                <div
                  onClick={() => setToggleSelectState(!toggleSelectState)}
                  className="flex justify-between w-full pb-1 cursor-pointer"
                >
                  <p className="font-body text-lg font-light text-fadedGray">
                    Select crypto
                  </p>
                  <img src="/img/icons/arrowdowngray.svg" />
                </div>
              ) : (
                <div
                  onClick={() => setToggleSelectState(!toggleSelectState)}
                  className="flex justify-between w-full pb-1"
                >
                  <div className="flex items-center gap-x-2">
                    <img className="" src={selectedToken.logopath} />
                    <p className="font-body text-lg font-light">
                      {selectedToken.tokenName}
                    </p>
                  </div>
                  <img src="/img/icons/arrowdown.svg" />
                </div>
              )}
            </div>

            <div
              className={`border ${
                toggleSelectState ? "flex" : "hidden"
              } flex-col gap-y-4  rounded-xl shadow-md p-4  absolute z-10 w-full bg-white top-[60px]`}
            >
              {tokensArray.map((token) => (
                <div
                  onClick={() => setToken(token)}
                  className="border-b border-b-formGray bg-white  px-2 flex item-center gap-x-2 pb-1 cursor-pointer"
                >
                  <img className="" src={token.logopath} />
                  <p className="font-body text-lg font-light">
                    {token.tokenName}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <label className="text-xs font-body">Enter amount($)</label>
            <input
              className="border-b-2 bg-white w-full text-textGray  py-3 px-4 focus:outline-none focus:ring-0 focus:border-gray-100 focus:bg-white"
              type={"number"}
              onChange={(e) => {
                e.target.value < 30
                  ? setError("minimum fund amount is $30")
                  : setError("");
                setAmount(Number(e.target.value));
              }}
              placeholder="Enter amount to deposit($)"
              required
            />
            {error && <FormError message={error} />}
          </div>
        </div>

        <div className="mt-10 flex flex-col gap-y-6">
          <PrimaryButton
            action={() => {
              setPages(pages + 1);
              setX(1000);
            }}
            text={"Continue"}
            disabled={selectedToken.tokenName === "" || amount < 30}
          />
        </div>
      </form>
    </motion.div>
  );
};
