import React from "react";
import { OnboardingNav } from "../Nav/BottomNav";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../buttons";

export const Onboarding = ({ linkto, heading, body, imgurl, next }) => {
  return (
    <div className="container pb-10 px-4 py-8 max-w-sm mx-auto relative  font-body flex flex-col">
      <div className="flex justify-between">
        <img className="w-48" src="/img/new/logo/logo.png" />
      </div>

      <h3 className="text-lg mt-4 font-fontRegular">{heading}</h3>

      <div>
        <img className=" rounded-lg" src="./img/new/background/home2.png" />
      </div>

      <p className="font-light mt-3 font-fontLight text-sm">{body}</p>

      <div className="bg-gray-50 p-4 my-2 grid grid-rows-2 gap-y-3 rounded-lg relative ">
        <div className="flex justify-between items-center">
          <h1 className="font-body font-medium">Welcome to NexteraEarning</h1>
          <img className=" w-14 top-0 right-0" src="./img/new/icons/btc.svg" />
        </div>
        <div className="flex gap-x-4  ">
          <Link to={"/signin"} className="w-full">
            <SecondaryButton text="Sign in" />
          </Link>
          <Link to={"/signup"} className="w-full">
            <PrimaryButton text="Get Started" />
          </Link>
        </div>
      </div>

      <div>
        <img className=" rounded-lg" src="./img/new/background/home1.png" />
      </div>

      <div className="border p-4 my-2 flex justify-between items-center gap-y-3 rounded-lg relative ">
        <div className="">
          <h5 className="font-body text-xs font-bold">
            Refer a friend and earn
          </h5>
          <p className="text-sm">
            5% reward for any downliner that registers with your link
          </p>
        </div>
        <img className=" w-14 top-0 right-0" src="./img/new/icons/usd.svg" />
      </div>
      <img
        className="absolute w-20 top-0 right-0"
        src="./img/new/background/btc.svg"
      />

      {/* <div className="flex flex-col gap-y-4 mx-auto w-[90%]">
        <Link to={"/signup"} className="w-full">
          <PrimaryButton text="Get Started" />
        </Link>
        <Link to={"/signin"} className="w-full">
          <SecondaryButton text="Sign in" />
        </Link>
      </div> */}
    </div>
  );
};
