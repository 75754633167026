import React, { useEffect } from "react";
import { InvestmentCard } from "../../../components/Cards/InvestmentCard";
import { BottomNav } from "../../../components/Nav/BottomNav";
import {
  claimInvestmentRequest,
  clearClaimInvestmentSuccess,
  getUserInvestmentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const Investment = () => {
  const dispatch = useDispatch();

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
    claimInvestmentSuccess,
    claimInvestmentError,
    claimInvestmentLoading,
    token,
  } = useSelector((state) => {
    const {
      success: { claimInvestment: claimInvestmentSuccess },
      errors: {
        getUserInvestments: getUserInvestmentsError,
        claimInvestment: claimInvestmentError,
      },
    } = state.ajaxStatuses;

    const { getUserInvestmentsLoading, claimInvestmentLoading } =
      state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      claimInvestmentSuccess,
      claimInvestmentError,
      claimInvestmentLoading,
      token,
    };
  });

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(user._id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen">
        <h2 className="font-body text-3xl">My Investment</h2>

        <div className="flex flex-col gap-y-1 mt-4">
          {getUserInvestmentsLoading ? (
            <div className="h-[20vh] flex items-center justify-center text-center">
              <CircularProgress size={20} className="text-primary" />
            </div>
          ) : (
            <>
              {investments.length === 0 ? (
                <div className="shadow-sm font-body text-textGray p-4 rounded-xl text-sm">
                  You do not have an active investment, fund your wallet and
                  start investing.
                </div>
              ) : (
                investments.map(
                  (investment, index) =>
                    investment.isActive && (
                      <InvestmentCard investment={investment} key={index} />
                    )
                )
              )}
            </>
          )}
        </div>
      </div>

      <div className="py-8 max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Investment;
