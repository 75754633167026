import React from "react";
import { BulletPoints } from "./BulletPoints";
import { Invest } from "./Invest";

export const InvestCard1 = ({ plan, img }) => {
  return (
    <div className="text-black rounded-lg overflow-hidden font-body shadow">
      <img className="rounded" src={img} />
      <div className="p-2">
        <div className="flex justify-between">
          <div className="flex items-center justify-center w-9 h-9 border border-black rounded-full text-xs">
            {plan.profitProduction}%
          </div>
          <h2 className="text-md">{plan.planName}</h2>
        </div>

        <p className="text-xs mt-2">
          Duration: <span className="">{plan.duration} day</span>
        </p>

        <p className="text-xs mt-0.5">Min: ${plan.minAmount}</p>

        <p className="text-xs mt-0.5 ">Max: ${plan.maxAmount}</p>

        <Invest fromWallet={false} />
      </div>
    </div>
  );
};
