import React from "react";
import {
  NavLink,
  Link,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "../../redux/action";
import { useDispatch } from "react-redux";
import { RiBankCardFill } from "react-icons/ri";
import { IoIosHome } from "react-icons/io";
import { BiLogOutCircle } from "react-icons/bi";

export const AdminNav = () => {
  const dispatch = useDispatch();

  const pathname = useLocation().pathname;
  const navLinks = [
    {
      name: "dashboard",
      Iconpath: IoIosHome,
      imgpathactive: "/img/icons/navhomeactive.svg",
    },
    {
      name: "request",
      Iconpath: RiBankCardFill,
      imgpathactive: "/img/icons/navwalletactive.svg",
    },
  ];

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div className="fixed mx-auto max-w-sm bg-white  bottom-0 w-full py-2 border-t">
      <div className="grid grid-cols-4 justify-items-center mx-auto">
        {navLinks.map(({ name, Iconpath, imgpathactive }, index) => (
          <NavLink
            key={index}
            to={`/admin/${name}`}
            activeClassName="text-primary"
            className="self-center font-body font-light"
          >
            <>
              {pathname === `/admin/${name}` ? (
                <div className="flex flex-col items-center">
                  <Iconpath className="text-primary text-3xl" />
                  <p className="text-xs">
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <Iconpath className="text-gray-400 text-3xl" />
                  <p className="text-xs">
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </p>
                </div>
              )}
            </>
          </NavLink>
        ))}
        <div
          onClick={logOut}
          className="flex flex-col items-center cursor-pointer"
        >
          <BiLogOutCircle className="text-gray-400 text-3xl" />
          <p className="text-xs">Logout</p>
        </div>

        <div></div>
      </div>
    </div>
  );
};
