import React, { useEffect } from "react";
import {
  claimInvestmentRequest,
  clearClaimInvestmentSuccess,
  getUserInvestmentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { AdminNav } from "../../../components/Nav/AdminNav";
import { AdminInvestmentCard } from "../../../components/Cards/AdminInvestmentCard";

const UserInvestments = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
    claimInvestmentSuccess,
    claimInvestmentError,
    claimInvestmentLoading,
    token,
  } = useSelector((state) => {
    const {
      success: { claimInvestment: claimInvestmentSuccess },
      errors: {
        getUserInvestments: getUserInvestmentsError,
        claimInvestment: claimInvestmentError,
      },
    } = state.ajaxStatuses;

    const { getUserInvestmentsLoading, claimInvestmentLoading } =
      state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      claimInvestmentSuccess,
      claimInvestmentError,
      claimInvestmentLoading,
      token,
    };
  });

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  useEffect(() => {
    if (claimInvestmentError) {
      toast.error(claimInvestmentError, {
        duration: 3000,
      });
    }
  }, [claimInvestmentError]);

  useEffect(() => {
    if (claimInvestmentSuccess) {
      toast.success(claimInvestmentSuccess, {
        duration: 3000,
      });

      dispatch(clearClaimInvestmentSuccess());
    }
  }, [claimInvestmentSuccess]);

  return (
    <div>
      <div className="shadow pt-3 pb-2">
        <div className="container mx-auto max-w-lg px-4">
          <h1 className="">User Investments</h1>
        </div>
      </div>
      <div className="container max-w-lg mx-auto flex flex-col gap-y-2 mt-5 mb-20 px-2">
        {getUserInvestmentsLoading ? (
          <div className="flex items-center justify-center py-44">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <>
            {investments.length === 0 ? (
              <div className="w-[320px] mx-auto shadow-md rounded px-2 py-4">
                <h1 className="text-md">No Active Investment</h1>
              </div>
            ) : (
              investments.map((data, index) => (
                <AdminInvestmentCard
                  key={index}
                  data={data}
                  loading={claimInvestmentLoading}
                  token={token}
                  getUserInvestmentsRequest={getUserInvestmentsRequest}
                />
              ))
            )}
          </>
        )}
      </div>
      <div className=" max-w-sm mx-auto w-full ">
        <AdminNav />
      </div>
    </div>
  );
};

export default UserInvestments;
