import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  updatePaymentRequest,
  clearUpdatePaymentSuccess,
  getUserRequest,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiFillCopy } from "react-icons/ai";
import { FormError } from "../Error";
import { PrimaryButton } from "../buttons";

export const RequestForm = ({ handleClose, data, getPaymentsRequest }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const {
    user,
    updatePaymentLoading,
    updatePaymentSuccess,
    updatePaymentError,
  } = useSelector((state) => {
    const {
      success: { updatePayment: updatePaymentSuccess },
      errors: { updatePayment: updatePaymentError },
    } = state.ajaxStatuses;

    const { updatePaymentLoading } = state.loadingIndicator;

    const { user } = state.userData;

    return {
      user,
      updatePaymentLoading,
      updatePaymentSuccess,
      updatePaymentError,
    };
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      user: "",
      status: "",
      amount: "",
      transactionRef: "",
      email: "",
      userName: "",
      walletAddress: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      prop.email = data.user.email;
      prop.userName = data.user.userName;
      prop.type = data.type;
      prop.user = data.user._id;
      prop.walletAddress = data.user.walletAddress;

      dispatch(updatePaymentRequest({ formData: prop, id: data._id }));
    },
  });

  useEffect(() => {
    if (updatePaymentError) {
      toast.error(updatePaymentError, {
        duration: 3000,
      });
    }
  }, [updatePaymentError]);

  useEffect(() => {
    if (updatePaymentSuccess) {
      toast.success(updatePaymentSuccess, {
        duration: 3000,
      });

      handleClose();

      dispatch(getPaymentsRequest());

      dispatch(clearUpdatePaymentSuccess());
    }
  }, [updatePaymentSuccess]);

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      status: data.status ? data.status : "",
      amount: data.amount,
    }));
  }, []);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);

    toast.success("Copied", {
      duration: 3000,
    });
  };

  return (
    <div className="container mx-auto max-w-xl mt-20 px-4 ">
      <h1 className="text-xl text-gray-700">{data.type && data.type}</h1>
      <h1 className="text-sm text-gray-700">
        {data.user && data.user.userName}
      </h1>
      <h1 className="text-sm text-gray-700">{data.user && data.user.email}</h1>
      <h1 className="text-sm text-gray-700">
        Balance: ${data.user.balance && data.user.balance}
      </h1>

      {data.imgReceiptUrl && (
        <div className="rounded mt-5 ">
          <img
            className="rounded w-full "
            src={data.imgReceiptUrl}
            alt="Payment receipt"
          ></img>
        </div>
      )}

      {data.transactionRef && (
        <div
          onClick={() => copyToClipboard(data.transactionRef)}
          className="rounded mt-5 cursor-pointer relative"
        >
          <label className="text-md">Transaction Ref:</label>
          <input
            type="text"
            className="block pt-3 pb-2 pl-2 pr-8 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
            placeholder="Amount"
            name="email"
            value={data.transactionRef}
          />
          <AiFillCopy className="absolute text-xl top-9 right-2" />
        </div>
      )}

      <div className="flex flex-col mt-5">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          Amount($)
        </label>
        <input
          type="number"
          name="first-name"
          id="first-name"
          autoComplete="given-name"
          {...formik.getFieldProps("amount")}
          className={
            error !== ""
              ? "border-red-500 mt-1 p-3 border   block w-full shadow-sm sm:text-sm rounded-md focus:outline-none transition duration-500"
              : "mt-1 p-3 border border-gray-400  focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm rounded-md transition duration-500"
          }
        />
        {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
      </div>

      <div className="flex flex-col mt-3">
        <div className="relative z-0 mb-6 w-full group">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            Status
          </label>
          <select
            id="type"
            {...formik.getFieldProps("status")}
            className="mt-1 p-3 border border-gray-400  focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm rounded-md transition duration-500 "
          >
            <option>Set Status</option>
            <option>Approved</option>
            <option>Pending</option>
            <option>Declined</option>
          </select>
          {formik.errors.type && formik.touched.type && (
            <FormError message={formik.errors.type} />
          )}
        </div>
      </div>

      {data.type === "Withdrawal" && (
        <div className="flex flex-col ">
          <label className="block text-sm font-medium text-gray-700">
            Transaction Ref
          </label>
          <input
            type="text"
            name="transactionRef"
            id="transactionRef"
            autoComplete="given-name"
            {...formik.getFieldProps("transactionRef")}
            className={
              "mt-1 p-3 border border-gray-400  focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm rounded-md transition duration-500"
            }
          />
        </div>
      )}

      <div className="mt-10">
        <PrimaryButton
          text="Confirm"
          action={formik.handleSubmit}
          loading={updatePaymentLoading}
        />
      </div>
    </div>
  );
};
