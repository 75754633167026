import {
  CREATE_PAYMENT_FAIL,
  CREATE_PAYMENT_LOADING,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CLEAR_CREATE_PAYMENT_SUCCESS,
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAIL,
  GET_PAYMENTS_LOADING,
  GET_USER_PAYMENTS_REQUEST,
  GET_USER_PAYMENTS_SUCCESS,
  GET_USER_PAYMENTS_FAIL,
  GET_USER_PAYMENTS_LOADING,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  CLEAR_UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_LOADING,
} from "../types";

export const createPaymentRequest = (prop) => {
  return {
    type: CREATE_PAYMENT_REQUEST,
    payload: prop,
  };
};

export const createPaymentSuccess = (prop) => {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: prop,
  };
};
export const clearCreatePaymentSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_PAYMENT_SUCCESS,
    payload: prop,
  };
};

export const createPaymentFailure = (errors) => {
  return {
    type: CREATE_PAYMENT_FAIL,
    payload: { errors },
  };
};

export const createPaymentLoading = (loading) => {
  return {
    type: CREATE_PAYMENT_LOADING,
    payload: loading,
  };
};

export const updatePaymentRequest = (prop) => {
  return {
    type: UPDATE_PAYMENT_REQUEST,
    payload: prop,
  };
};

export const updatePaymentSuccess = (prop) => {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload: prop,
  };
};
export const clearUpdatePaymentSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_PAYMENT_SUCCESS,
    payload: prop,
  };
};

export const updatePaymentFailure = (errors) => {
  return {
    type: UPDATE_PAYMENT_FAIL,
    payload: { errors },
  };
};

export const updatePaymentLoading = (loading) => {
  return {
    type: UPDATE_PAYMENT_LOADING,
    payload: loading,
  };
};

export const getPaymentsRequest = (prop) => {
  return {
    type: GET_PAYMENTS_REQUEST,
    payload: prop,
  };
};

export const getPaymentsSuccess = (prop) => {
  return {
    type: GET_PAYMENTS_SUCCESS,
    payload: prop,
  };
};

export const getPaymentsFailure = (errors) => {
  return {
    type: GET_PAYMENTS_FAIL,
    payload: { errors },
  };
};

export const getPaymentsLoading = (loading) => {
  return {
    type: GET_PAYMENTS_LOADING,
    payload: loading,
  };
};

export const getUserPaymentsRequest = (prop) => {
  return {
    type: GET_USER_PAYMENTS_REQUEST,
    payload: prop,
  };
};

export const getUserPaymentsSuccess = (prop) => {
  return {
    type: GET_USER_PAYMENTS_SUCCESS,
    payload: prop,
  };
};

export const getUserPaymentsFailure = (errors) => {
  return {
    type: GET_USER_PAYMENTS_FAIL,
    payload: { errors },
  };
};

export const getUserPaymentsLoading = (loading) => {
  return {
    type: GET_USER_PAYMENTS_LOADING,
    payload: loading,
  };
};
