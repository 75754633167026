import React, { useEffect, useState } from "react";
import { TransactionCard } from "../../../components/Cards/TransactionCard";
import { BottomNav } from "../../../components/Nav/BottomNav";
import { Wallet } from "../../../components/Wallet";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInvestmentsRequest,
  getUserPaymentsRequest,
} from "../../../redux/action";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const Transaction = () => {
  const dispatch = useDispatch();

  const {
    user,
    getUserPaymentsError,
    payments,
    getUserPaymentsLoading,
    getUserInvestmentsLoading,
    investments,
    getUserInvestmentsError,
  } = useSelector((state) => {
    const {
      success: { claimInvestment: claimInvestmentSuccess },
      errors: {
        getUserPayments: getUserPaymentsError,
        claimInvestment: claimInvestmentError,
        getUserInvestments: getUserInvestmentsError,
      },
    } = state.ajaxStatuses;

    const { getUserPaymentsLoading, getUserInvestmentsLoading } =
      state.loadingIndicator;

    const { user } = state.userData;

    const { payments } = state.paymentData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserPaymentsError,
      payments,
      getUserPaymentsLoading,
      getUserInvestmentsLoading,
      investments,
      getUserInvestmentsError,
    };
  });

  useEffect(() => {
    dispatch(getUserPaymentsRequest(user._id));
  }, [getUserPaymentsRequest]);

  useEffect(() => {
    if (getUserPaymentsError) {
      toast.error(getUserPaymentsError, {
        duration: 3000,
      });
    }
  }, [getUserPaymentsError]);

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(user._id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  const [activeInvestment, setActiveInvestment] = useState(0);
  const [expectedReturn, setExpectedReturn] = useState(0);

  useEffect(() => {
    if (!getUserInvestmentsLoading) {
      let amountInvested = 0;
      let expectedInvested = 0;

      for (let i = 0; i < investments.length; i++) {
        if (investments[i].isActive) {
          amountInvested += investments[i].amount;
          expectedInvested += investments[i].expectedReturn;
        }
      }

      setActiveInvestment(amountInvested);
      setExpectedReturn(expectedInvested);
    }
  }, [getUserInvestmentsLoading]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen">
        <h2 className="font-body font-Medium text-3xl">My Wallet</h2>
        <Wallet
          activeInvestment={activeInvestment}
          expectedReturn={expectedReturn}
          user={user}
          isHome={false}
          getUserInvestmentsLoading={getUserInvestmentsLoading}
          investments={investments}
        />

        <div className="my-6">
          <p className="font-body text-base">Transaction History</p>

          <div className="flex flex-col gap-y-2 mt-4">
            {getUserPaymentsLoading ? (
              <div className="h-[20vh] flex items-center justify-center text-center">
                <CircularProgress size={20} className="text-primary" />
              </div>
            ) : (
              <>
                {payments.map((payment, index) => (
                  <TransactionCard payment={payment} key={index} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="py-8 max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Transaction;
