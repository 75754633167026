import React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { OnboardingNav } from "../../../components/Nav/BottomNav";
import { Onboarding } from "../../../components/Sections/Onboarding";

const Home2 = () => {
  return (
    <div>
      <Onboarding
        linkto={"/signup"}
        heading={"The Essentials Platform"}
        body={
          "If it's essential, we have the solution. The only platform of its kind to deliver end-to-end solutions across all segments of fulfillment."
        }
        imgurl={"/img/new/background/home2.jpeg"}
        next={"/home3"}
      />
    </div>
  );
};

export default Home2;
