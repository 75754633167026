import React, { useEffect, useState } from "react";
import {
  clearUpdateUserSuccess,
  getLogUserRequest,
  getReferralRequest,
  updateUserRequest,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PrimaryButton } from "../buttons";
import { UsersCard } from "../Cards/UserCard";
import { FormError } from "../Error";

export const ProfileForm = ({ user, loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    updateUserError,
    updateUserSuccess,
    updateUserLoading,
    token,
    singleUser,
    referralUser,
  } = useSelector((state) => {
    const {
      success: { updateUser: updateUserSuccess },
      errors: { updateUser: updateUserError },
    } = state.ajaxStatuses;

    const { updateUserLoading } = state.loadingIndicator;

    const { user, token, singleUser, referralUser } = state.userData;

    return {
      singleUser,
      updateUserError,
      updateUserSuccess,
      updateUserLoading,
      token,
      referralUser,
    };
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      phone: "",
      walletAddress: "",
      referralBonus: "",
      balance: "",
    },
    validationSchema: yup.object({
      userName: yup
        .string()
        .required("User name is required")
        .max(32, "User name is too long")
        .trim()
        .required("Username is required"),
      phone: yup.string().required("Phone is required"),
      walletAddress: yup.string().required("walletAddress is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      const id = user._id;
      console.log(prop);

      dispatch(updateUserRequest({ formData, id }));
    },
  });

  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toast.success(updateUserSuccess, {
        duration: 3000,
      });

      dispatch(clearUpdateUserSuccess());
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    dispatch(getLogUserRequest(token));
  }, [getLogUserRequest]);

  useEffect(() => {
    if (user.referralUser) {
      dispatch(getReferralRequest(user.referralUser));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        userName: user.userName,
        phone: user.phone,
        walletAddress: user.walletAddress,
        referralBonus: user.referralBonus,
        balance: user.balance,
      }));
    }
  }, [user]);

  return (
    <>
      <div className="mt-5">
        {loading ? (
          <div className="flex items-center justify-center py-16">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <UsersCard user={user} />
        )}

        {user.referralUser && (
          <div className="text-center text-sm">
            <h1>Referred by: {referralUser.email} </h1>
          </div>
        )}
      </div>

      <div className="container max-w-lg mx-auto flex flex-col px-4 mt-5">
        <div className="px-2 py-4">
          <div className="flex justify-between">
            <h1 className="text-md">Update details</h1>
            <Link
              to={`/admin/investments/${user._id}`}
              className=" bg-primary flex items-center px-3 rounded-full text-sm text-dark"
            >
              Investments
            </Link>
          </div>

          <form className="max-w-sm mx-auto mt-4">
            <div className="mb-4">
              <div className="relative z-0  w-full bg-white rounded group">
                <input
                  type="username"
                  name="floating_usename"
                  className="block pt-6 pb-2 px-4 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
                  placeholder=" "
                  {...formik.getFieldProps("userName")}
                  required
                />
                <label
                  htmlFor="floating_username"
                  className="absolute text-md text-dark-500 duration-300 transform -translate-y-3 scale-75 top-4 -z-10 origin-[0] left-4 peer-focus:left-4 peer-focus:text-gray-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
                >
                  Username
                </label>
              </div>
              {formik.errors.userName && formik.touched.userName && (
                <FormError message={formik.errors.userName} />
              )}
            </div>

            <div className="mb-4">
              <div className="relative z-0  w-full bg-white rounded group">
                <input
                  type="tel"
                  name="phone"
                  className="block pt-6 pb-2 px-4 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
                  placeholder=" "
                  {...formik.getFieldProps("phone")}
                  required
                />
                <label
                  htmlFor="phone"
                  className="absolute text-md text-dark-500 duration-300 transform -translate-y-3 scale-75 top-4 -z-10 origin-[0] left-4 peer-focus:left-4 peer-focus:text-gray-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
                >
                  Phone
                </label>
              </div>
              {formik.errors.phone && formik.touched.phone && (
                <FormError message={formik.errors.phone} />
              )}
            </div>
            <div className="mb-4">
              <div className="relative z-0  w-full bg-white rounded group">
                <input
                  type="text"
                  name="walletAddress"
                  className="block pt-6 pb-2 px-4 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
                  placeholder=" "
                  {...formik.getFieldProps("walletAddress")}
                  required
                />
                <label
                  htmlFor="walletAddress"
                  className="absolute text-md text-dark-500 duration-300 transform -translate-y-3 scale-75 top-4 -z-10 origin-[0] left-4 peer-focus:left-4 peer-focus:text-gray-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
                >
                  Wallet Address
                </label>
              </div>
              {formik.errors.walletAddress && formik.touched.walletAddress && (
                <FormError message={formik.errors.walletAddress} />
              )}
            </div>
            <div className="mb-4">
              <div className="relative z-0  w-full bg-white rounded group">
                <input
                  type="number"
                  name="referralBonus"
                  className="block pt-6 pb-2 px-4 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
                  placeholder=" "
                  {...formik.getFieldProps("referralBonus")}
                  required
                />
                <label
                  htmlFor="referralBonus"
                  className="absolute text-md text-dark-500 duration-300 transform -translate-y-3 scale-75 top-4 -z-10 origin-[0] left-4 peer-focus:left-4 peer-focus:text-gray-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
                >
                  Referral Bonus($)
                </label>
              </div>
              {formik.errors.referralBonus && formik.touched.referralBonus && (
                <FormError message={formik.errors.referralBonus} />
              )}
            </div>
            <div className="mb-4">
              <div className="relative z-0  w-full bg-white rounded group">
                <input
                  type="number"
                  name="balance"
                  className="block pt-6 pb-2 px-4 w-full text-md text-dark bg-transparent border border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer rounded"
                  placeholder=" "
                  {...formik.getFieldProps("balance")}
                  required
                />
                <label
                  htmlFor="balance"
                  className="absolute text-md text-dark-500 duration-300 transform -translate-y-3 scale-75 top-4 -z-10 origin-[0] left-4 peer-focus:left-4 peer-focus:text-gray-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
                >
                  Balance($)
                </label>
              </div>
              {formik.errors.balance && formik.touched.balance && (
                <FormError message={formik.errors.balance} />
              )}
            </div>

            <div className="text-center pt-4 mb-4">
              <PrimaryButton
                text={"Update"}
                action={formik.handleSubmit}
                loading={updateUserLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
