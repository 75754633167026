import { useState } from "react";
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { format } from "date-fns";
import { FaTimes } from "react-icons/fa";
import Modal from "@material-ui/core/Modal";
import { RequestForm } from "../Forms/RequestForm";

export const RequestCard = ({ data, getPaymentsRequest }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 "
      >
        <div className=" h-full w-full  rounded  bg-white outline-0 overflow-scroll relative transition duration-500 pb-5">
          <FaTimes
            onClick={handleClose}
            className="cursor-pointer  text-2xl absolute right-5 top-5"
          />
          <RequestForm
            handleClose={handleClose}
            data={data}
            getPaymentsRequest={getPaymentsRequest}
          />
        </div>
      </Modal>
      <div className="rounded border mx-2 flex p-2 gap-x-4 items-center">
        <div className="self-center">
          {data.type && data.type === "Deposite" ? (
            <ImArrowDown className=" text-2xl text-green-500" />
          ) : (
            <ImArrowUp className=" text-2xl text-red-500" />
          )}
        </div>
        <div>
          <h3 className="text-sm">{data.type && data.type}</h3>
          <p className="text-xs">
            {data.createdAt && format(Date.parse(data.createdAt), "MM/dd/yyyy")}
          </p>
        </div>
        <div>
          <h3 className="text-sm ">Status</h3>
          <p className="text-xs ">{data.status && data.status}</p>
        </div>
        {/* <div>
          <div className="text-xs border-2 border-primary rounded-md px-4">
            View
          </div>
        </div> */}
        {data.type === "Deposite" ? (
          <h3 className="text-sm text-green-500 ml-auto">
            +${data.amount && data.amount}
          </h3>
        ) : (
          <h3 className="text-sm text-red-500 ml-auto">
            -${data.amount && data.amount}
          </h3>
        )}

        <div
          onClick={handleOpen}
          className=" ml-auto   text-primary flex items-center justify-center text-sm h-6 cursor-pointer "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
