import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  clearUpdateInvestmentsSuccess,
  getUsersRequest,
  updateInvestmentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UsersCard } from "../../../components/Cards/UserCard";
import { AdminNav } from "../../../components/Nav/AdminNav";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    users,
    user,
    getUsersError,
    investments,
    getUsersLoading,
    token,
    updateInvestmentsLoading,
    updateInvestmentsError,
    updateInvestmentsSuccess,
  } = useSelector((state) => {
    const {
      success: {
        getUsers: getUsersSuccess,
        updateInvestments: updateInvestmentsSuccess,
      },
      errors: {
        getUsers: getUsersError,
        updateInvestments: updateInvestmentsError,
      },
    } = state.ajaxStatuses;

    const { getUsersLoading, updateInvestmentsLoading } =
      state.loadingIndicator;

    const { user, users, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      users,
      getUsersError,
      investments,
      updateInvestmentsLoading,
      updateInvestmentsError,
      updateInvestmentsSuccess,
      getUsersLoading,
      token,
      user,
    };
  });

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [getUsersRequest]);

  useEffect(() => {
    if (getUsersError) {
      toast.error(getUsersError, {
        duration: 3000,
      });
    }
  }, [getUsersError]);

  useEffect(() => {
    if (updateInvestmentsSuccess) {
      toast.success(updateInvestmentsSuccess, {
        duration: 3000,
      });

      dispatch(clearUpdateInvestmentsSuccess());
    }
  }, [updateInvestmentsSuccess]);

  useEffect(() => {
    if (updateInvestmentsError) {
      toast.error(updateInvestmentsError, {
        duration: 3000,
      });
    }
  }, [updateInvestmentsError]);

  const updateAllInvestment = () => {
    dispatch(updateInvestmentsRequest({ user: user._id }));
  };

  return (
    <div>
      <div className="shadow pt-3 pb-2">
        <div className="container mx-auto max-w-lg px-4">
          <h1 className="">Users</h1>
        </div>
      </div>

      <div className="container max-w-lg mx-auto px-2 mt-5">
        <div className="border rounded p-4 flex gap-x-8 justify-between">
          <h5 className="font-heading text-md">Update investment</h5>
          <div className="w-40">
            {/* <MediumBotton
              action={updateAllInvestment}
              loading={updateInvestmentsLoading}
              text="Update"
            /> */}
          </div>
        </div>
      </div>

      <div className="container max-w-lg mx-auto flex flex-col gap-y-2 mt-5 mb-20">
        {getUsersLoading ? (
          <div className="flex items-center justify-center py-44">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <>
            {users.map((user, index) => (
              <UsersCard key={index} user={user} />
            ))}
          </>
        )}
      </div>

      <div className=" max-w-sm mx-auto w-full ">
        <AdminNav />
      </div>
    </div>
  );
};

export default Dashboard;
