import React, { useEffect } from "react";
import {
  claimInvestmentRequest,
  clearClaimInvestmentSuccess,
  getPaymentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { RequestCard } from "../../../components/Cards/RequestCard";
import { AdminNav } from "../../../components/Nav/AdminNav";

const Request = () => {
  const dispatch = useDispatch();

  const { user, getPaymentsError, payments, getPaymentsLoading } = useSelector(
    (state) => {
      const {
        success: { claimInvestment: claimInvestmentSuccess },
        errors: {
          getPayments: getPaymentsError,
          claimInvestment: claimInvestmentError,
        },
      } = state.ajaxStatuses;

      const { getPaymentsLoading } = state.loadingIndicator;

      const { user } = state.userData;

      const { payments } = state.paymentData;

      return {
        user,
        getPaymentsError,
        payments,
        getPaymentsLoading,
      };
    }
  );

  useEffect(() => {
    dispatch(getPaymentsRequest(user._id));
  }, [getPaymentsRequest]);

  useEffect(() => {
    if (getPaymentsError) {
      toast.error(getPaymentsError, {
        duration: 3000,
      });
    }
  }, [getPaymentsError]);

  return (
    <div>
      <div className="shadow pt-3 pb-2">
        <div className="container mx-auto max-w-lg px-4">
          <h1 className="">All Request</h1>
        </div>
      </div>

      <div className="container max-w-lg mx-auto flex flex-col gap-y-2 mt-5 mb-20">
        {getPaymentsLoading ? (
          <div className="flex items-center justify-center py-44">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <>
            {payments.length !== 0 &&
              payments.map((data, index) => (
                <RequestCard
                  key={index}
                  data={data}
                  getPaymentsRequest={getPaymentsRequest}
                />
              ))}
          </>
        )}
      </div>
      <div className=" max-w-sm mx-auto w-full ">
        <AdminNav />
      </div>
    </div>
  );
};

export default Request;
