import {
  CLEAR_CREATE_PLAN_SUCCESS,
  CLEAR_UPDATE_USER_SUCCESS,
  CLEAR_UPDATE_PASSWORD_SUCCESS,
  CREATE_PLAN_FAIL,
  CREATE_PLAN_SUCCESS,
  CREATE_PAYMENT_FAIL,
  CREATE_PAYMENT_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLANS_SUCCESS,
  GET_PAYMENTS_FAIL,
  GET_PAYMENTS_SUCCESS,
  GET_WITHDRAWS_FAIL,
  GET_WITHDRAWS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_INVESTMENTS_FAIL,
  GET_USER_INVESTMENTS_SUCCESS,
  GET_INVESTMENT_FAIL,
  GET_INVESTMENT_SUCCESS,
  GET_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
  CLEAR_REGISTER_SUCCESS,
  CLEAR_LOGIN_SUCCESS,
  CREATE_INVESTMENT_FAIL,
  CLEAR_CREATE_INVESTMENT_SUCCESS,
  CREATE_INVESTMENT_SUCCESS,
  UPDATE_INVESTMENT_FAIL,
  UPDATE_INVESTMENT_SUCCESS,
  CLEAR_UPDATE_INVESTMENT_SUCCESS,
  CLEAR_CREATE_PAYMENT_SUCCESS,
  CLEAR_CREATE_WITHDRAW_SUCCESS,
  CREATE_WITHDRAW_SUCCESS,
  CREATE_WITHDRAW_FAIL,
  GET_LOGINUSER_FAIL,
  GET_LOGINUSER_SUCCESS,
  GET_RESETLINK_FAIL,
  GET_RESETLINK_SUCCESS,
  CLEAR_GET_RESETLINK_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_SUCCESS,
  CREATE_MAIL_FAIL,
  CREATE_MAIL_SUCCESS,
  CLEAR_CREATE_MAIL_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  CLEAR_DELETE_USER_SUCCESS,
  CLAIM_INVESTMENT_FAIL,
  CLAIM_INVESTMENT_SUCCESS,
  CLEAR_CLAIM_INVESTMENT_SUCCESS,
  GET_USER_PAYMENTS_FAIL,
  GET_USER_PAYMENTS_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
  CLEAR_UPDATE_PAYMENT_SUCCESS,
  UPDATE_INVESTMENTS_FAIL,
  UPDATE_INVESTMENTS_SUCCESS,
  CLEAR_UPDATE_INVESTMENTS_SUCCESS,
} from "../types";

const initialState = {
  errors: {},
  success: {},
};

const ajaxStatuses = (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_FAIL:
      state.errors.register = payload.errors;
      return { ...state };
    case REGISTER_SUCCESS:
      state.success.register = payload.message;
      return { ...state };
    case CLEAR_REGISTER_SUCCESS:
      state.success.register = "";
      return { ...state };
    case LOGIN_FAIL:
      state.errors.login = payload.errors;
      return { ...state };
    case LOGIN_SUCCESS:
      state.success.login = payload.message;
      return { ...state };
    case CLEAR_LOGIN_SUCCESS:
      state.success.login = "";
      return { ...state };
    case DELETE_USER_FAIL:
      state.errors.deleteUser = payload.errors;
      return { ...state };
    case DELETE_USER_SUCCESS:
      state.success.deleteUser = payload.message;
      return { ...state };
    case CLEAR_DELETE_USER_SUCCESS:
      state.success.deleteUser = "";
      return { ...state };
    case CREATE_PLAN_FAIL:
      state.errors.createPlan = payload.errors;
      return { ...state };
    case CREATE_PLAN_SUCCESS:
      state.success.createPlan = payload.message;
      return { ...state };
    case CREATE_WITHDRAW_FAIL:
      state.errors.createWithdraw = payload.errors;
      return { ...state };
    case CREATE_WITHDRAW_SUCCESS:
      state.success.createWithdraw = payload.message;
      return { ...state };
    case CLEAR_CREATE_WITHDRAW_SUCCESS:
      state.success.createWithdraw = "";
      return { ...state };

    case UPDATE_PAYMENT_FAIL:
      state.errors.updatePayment = payload.errors;
      return { ...state };
    case UPDATE_PAYMENT_SUCCESS:
      state.success.updatePayment = payload.message;
      return { ...state };
    case CLEAR_UPDATE_PAYMENT_SUCCESS:
      state.success.updatePayment = "";
      return { ...state };

    case CREATE_PAYMENT_FAIL:
      state.errors.createPayment = payload.errors;
      return { ...state };
    case CREATE_PAYMENT_SUCCESS:
      state.success.createPayment = payload.message;
      return { ...state };
    case CLEAR_CREATE_PAYMENT_SUCCESS:
      state.success.createPayment = "";
      return { ...state };
    case GET_LOGINUSER_FAIL:
      state.errors.getLogUser = payload.errors;
      return { ...state };
    case GET_LOGINUSER_SUCCESS:
      state.success.getLogUser = payload.message;
      return { ...state };
    case GET_PLANS_FAIL:
      state.errors.getPlans = payload.errors;
      return { ...state };
    case GET_PLANS_SUCCESS:
      state.success.getPlans = payload.message;
      return { ...state };
    case GET_PAYMENTS_FAIL:
      state.errors.getPayments = payload.errors;
      return { ...state };
    case GET_PAYMENTS_SUCCESS:
      state.success.getPayments = payload.message;
      return { ...state };
    case GET_USER_PAYMENTS_FAIL:
      state.errors.getUserPayments = payload.errors;
      return { ...state };
    case GET_USER_PAYMENTS_SUCCESS:
      state.success.getUserPayments = payload.message;
      return { ...state };
    case GET_WITHDRAWS_FAIL:
      state.errors.getWithdraws = payload.errors;
      return { ...state };
    case GET_WITHDRAWS_SUCCESS:
      state.success.getWithdraws = payload.message;
      return { ...state };
    case GET_USERS_FAIL:
      state.errors.getUsers = payload.errors;
      return { ...state };
    case GET_USERS_SUCCESS:
      state.success.getUsers = payload.message;
      return { ...state };
    case GET_USER_FAIL:
      state.errors.getUser = payload.errors;
      return { ...state };
    case GET_USER_SUCCESS:
      state.success.getUser = payload.message;
      return { ...state };
    case UPDATE_USER_FAIL:
      state.errors.updateUser = payload.errors;
      return { ...state };
    case UPDATE_USER_SUCCESS:
      state.success.updateUser = payload.message;
      return { ...state };
    case CLEAR_UPDATE_USER_SUCCESS:
      state.success.updateUser = "";
      return { ...state };
    case GET_USER_INVESTMENTS_FAIL:
      state.errors.getUserInvestments = payload.errors;
      return { ...state };
    case GET_USER_INVESTMENTS_SUCCESS:
      state.success.getUserInvestments = payload.message;
      return { ...state };

    case GET_INVESTMENT_FAIL:
      state.errors.getInvestment = payload.errors;
      return { ...state };
    case GET_INVESTMENT_SUCCESS:
      state.success.getInvestment = payload.message;
      return { ...state };

    case CREATE_INVESTMENT_FAIL:
      state.errors.createInvestment = payload.errors;
      return { ...state };
    case CREATE_INVESTMENT_SUCCESS:
      state.success.createInvestment = payload.message;
      return { ...state };
    case CLEAR_CREATE_INVESTMENT_SUCCESS:
      state.success.createInvestment = "";
      return { ...state };

    case CLAIM_INVESTMENT_FAIL:
      state.errors.claimInvestment = payload.errors;
      return { ...state };
    case CLAIM_INVESTMENT_SUCCESS:
      state.success.claimInvestment = payload.message;
      return { ...state };
    case CLEAR_CLAIM_INVESTMENT_SUCCESS:
      state.success.claimInvestment = "";
      return { ...state };

    case GET_RESETLINK_FAIL:
      state.errors.getResetLink = payload.errors;
      return { ...state };
    case GET_RESETLINK_SUCCESS:
      state.success.getResetLink = payload.message;
      return { ...state };
    case CLEAR_GET_RESETLINK_SUCCESS:
      state.success.getResetLink = "";
      return { ...state };

    case UPDATE_INVESTMENT_FAIL:
      state.errors.updateInvestment = payload.errors;
      return { ...state };
    case UPDATE_INVESTMENT_SUCCESS:
      state.success.updateInvestment = payload.message;
      return { ...state };
    case CLEAR_UPDATE_INVESTMENT_SUCCESS:
      state.success.updateInvestment = "";
      return { ...state };

    case UPDATE_INVESTMENTS_FAIL:
      state.errors.updateInvestments = payload.errors;
      return { ...state };
    case UPDATE_INVESTMENTS_SUCCESS:
      state.success.updateInvestments = payload.message;
      return { ...state };
    case CLEAR_UPDATE_INVESTMENTS_SUCCESS:
      state.success.updateInvestments = "";
      return { ...state };

    case UPDATE_PASSWORD_FAIL:
      state.errors.updatePassword = payload.errors;
      return { ...state };
    case UPDATE_PASSWORD_SUCCESS:
      state.success.updatePassword = payload.message;
      return { ...state };
    case CLEAR_UPDATE_PASSWORD_SUCCESS:
      state.success.updatePassword = "";
      return { ...state };
    case RESET_PASSWORD_FAIL:
      state.errors.resetPassword = payload.errors;
      return { ...state };
    case RESET_PASSWORD_SUCCESS:
      state.success.resetPassword = payload.message;
      return { ...state };
    case CLEAR_RESET_PASSWORD_SUCCESS:
      state.success.resetPassword = "";
      return { ...state };
    case CREATE_MAIL_FAIL:
      state.errors.createMail = payload.errors;
      return { ...state };
    case CREATE_MAIL_SUCCESS:
      state.success.createMail = payload.message;
      return { ...state };
    case CLEAR_CREATE_MAIL_SUCCESS:
      state.success.createMail = "";
      return { ...state };
    default:
      return state;
  }
};

export default ajaxStatuses;
