import React from "react";
import { Invest } from "../Cards/Invest";
import { Deposite } from "../Deposite/Deposite";
import Withdraw from "../Withdraw/Withdraw";
import { RiArrowUpSFill } from "react-icons/ri";

export const Wallet = ({
  activeInvestment,
  expectedReturn,
  isHome,
  user,
  getUserInvestmentsLoading,
  investments,
}) => {
  return (
    <div>
      <div
        className={`relative bg-primary p-4 w-full font-body mx-auto text-dark mt-6 rounded-lg flex flex-col`}
      >
        <div className="relative gap-y-4">
          <h2 className="font-medium text-sm">My Portfolio</h2>
        </div>
        <div className="relative flex gap-x-2">
          <img className=" w-6 top-0 right-0" src="/img/new/icons/usd.svg" />
          <h3 className="font-fontRegular text-lg">
            $ {user.balance ? user.balance : 0}
            <span className="text-lg">.00</span>
          </h3>

          <div className="flex bg-white rounded text-green-600 font-fontLight items-center px-2">
            <RiArrowUpSFill />
            <h5 className="text-xs">${expectedReturn}</h5>
          </div>
        </div>

        <div className="flex flex-col font-fontLight">
          <h5 className="text-xs ">Active Invested</h5>
          <h5 className="text-xl ">${activeInvestment}</h5>
        </div>
      </div>

      <div className="flex justify-between mt-5 px-2">
        <Deposite fromWallet={true} />
        <Withdraw fromWallet={true} />
        <Invest fromWallet={true} />
      </div>
    </div>
  );
};
