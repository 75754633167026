import React, { useEffect } from "react";
import { FormError } from "../../../components/Error";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { loginRequest, clearLoginSuccess } from "../../../redux/action";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../../components/buttons";

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { loginSuccess, loginError, loginLoading, user } = useSelector(
    (state) => {
      const {
        success: { login: loginSuccess },
        errors: { login: loginError },
      } = state.ajaxStatuses;

      const { loginLoading } = state.loadingIndicator;

      const { user } = state.userData;

      return {
        loginSuccess,
        loginError,
        loginLoading,
        user,
      };
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().required("Enter email"),
      password: yup.string().required("Enter your password"),
    }),

    onSubmit: (prop) => {
      console.log(prop);
      dispatch(loginRequest(prop));
    },
  });

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, {
        duration: 3000,
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (loginSuccess) {
      toast.success(loginSuccess, {
        duration: 3000,
      });

      history.push("/admin/dashboard");

      dispatch(clearLoginSuccess());
    }
  }, [loginSuccess]);

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen">
        <div className="">
          <img className="w-32" src="img/new/logo/logo.png" />
        </div>

        <div className="flex flex-col gap-y-2 px-4 mt-14">
          <h1 className="font-body font-bold text-2xl text-left">
            {" "}
            Admin Login
          </h1>
        </div>

        <form className="max-w-xs mx-auto mt-8">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Email Address</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"text"}
                {...formik.getFieldProps("email")}
                placeholder="Your email address"
                required
              />
              {formik.errors.email && formik.touched.email && (
                <FormError message={formik.errors.email} />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="text-xs font-body">Password</label>
              <input
                className="bg-formGray text-textGray w-full text-sm rounded border py-3 px-4 focus:outline-none focus:ring-0"
                type={"password"}
                {...formik.getFieldProps("password")}
                placeholder="Password"
                required
              />
              {formik.errors.password && formik.touched.password && (
                <FormError message={formik.errors.password} />
              )}
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-y-6">
            <PrimaryButton
              text={"Login"}
              action={formik.handleSubmit}
              loading={loginLoading}
              disabled={!formik.isValid}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
