import {
  CREATE_WITHDRAW_FAIL,
  CREATE_WITHDRAW_LOADING,
  CREATE_WITHDRAW_REQUEST,
  CREATE_WITHDRAW_SUCCESS,
  CLEAR_CREATE_WITHDRAW_SUCCESS,
  GET_WITHDRAWS_REQUEST,
  GET_WITHDRAWS_SUCCESS,
  GET_WITHDRAWS_FAIL,
  GET_WITHDRAWS_LOADING,
} from "../types";

export const createWithdrawRequest = (prop) => {
  return {
    type: CREATE_WITHDRAW_REQUEST,
    payload: prop,
  };
};

export const createWithdrawSuccess = (prop) => {
  return {
    type: CREATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};
export const clearCreateWithdrawSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};

export const createWithdrawFailure = (errors) => {
  return {
    type: CREATE_WITHDRAW_FAIL,
    payload: { errors },
  };
};

export const createWithdrawLoading = (loading) => {
  return {
    type: CREATE_WITHDRAW_LOADING,
    payload: loading,
  };
};

export const getWithdrawsRequest = (prop) => {
  return {
    type: GET_WITHDRAWS_REQUEST,
    payload: prop,
  };
};

export const getWithdrawsSuccess = (prop) => {
  return {
    type: GET_WITHDRAWS_SUCCESS,
    payload: prop,
  };
};

export const getWithdrawsFailure = (errors) => {
  return {
    type: GET_WITHDRAWS_FAIL,
    payload: { errors },
  };
};

export const getWithdrawsLoading = (loading) => {
  return {
    type: GET_WITHDRAWS_LOADING,
    payload: loading,
  };
};
