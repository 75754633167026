import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeleteUserSuccess,
  deleteUserRequest,
  getUsersRequest,
} from "../../redux/action";
import { toast } from "react-toastify";

export const DeleteUserCard = ({ handleClose, userId }) => {
  const dispatch = useDispatch();

  const { deleteUserSuccess, deleteUserError, deleteUserLoading, user, token } =
    useSelector((state) => {
      const {
        success: { deleteUser: deleteUserSuccess },
        errors: { deleteUser: deleteUserError },
      } = state.ajaxStatuses;

      const { deleteUserLoading } = state.loadingIndicator;

      const { user, token } = state.userData;

      return {
        deleteUserSuccess,
        deleteUserError,
        deleteUserLoading,
        user,
        token,
      };
    });

  const deleteUser = () => {
    const id = userId;
    dispatch(deleteUserRequest({ id, token }));
  };

  useEffect(() => {
    if (deleteUserError) {
      toast.error(deleteUserError, {
        duration: 3000,
      });
    }
  }, [deleteUserError]);

  useEffect(() => {
    if (deleteUserSuccess) {
      toast.success(deleteUserSuccess, {
        duration: 3000,
      });
      handleClose();

      dispatch(getUsersRequest());
      dispatch(clearDeleteUserSuccess());
    }
  }, [deleteUserSuccess]);

  return (
    <div className="shadow bg-white h-[200px]  w-[320px] rounded py-16 px-4">
      <p className="text-sm">
        Are you sure you want to delete this user? This action cannot be
        reversed
      </p>
      <div className="flex gap-x-2 mt-5 w-[220px] ml-auto">
        <div
          onClick={handleClose}
          className="border-2 text-sm border-primary rounded-md w-[100px] text-center py-1 cursor-pointer"
        >
          No
        </div>
        <div
          onClick={() => deleteUser()}
          className="bg-red-500 text-sm text-white rounded-md w-[100px] text-center py-1 cursor-pointer"
        >
          {deleteUserLoading ? (
            <CircularProgress style={{ color: "black" }} size="20px" />
          ) : (
            "Yes"
          )}
        </div>
      </div>
    </div>
  );
};
