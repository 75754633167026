import React from "react";
import { Switch, Route } from "react-router-dom";
import About from "./About";
import Home from "./Home";
import Investment from "./Investment";
import Profile from "./Profile";
import Refer from "./Refer";
import Security from "./Security";
import Setting from "./Settings";
import Transaction from "./Transaction";
import Plandetails from "./Plandetails";

const Dashboard = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/home`}>
        <Home />
      </Route>
      <Route exact path={`${path}/transaction`}>
        <Transaction />
      </Route>
      <Route exact path={`${path}/investment`}>
        <Investment />
      </Route>
      <Route exact path={`${path}/setting`}>
        <Setting />
      </Route>
      <Route exact path={`${path}/profile`}>
        <Profile />
      </Route>
      <Route exact path={`${path}/security`}>
        <Security />
      </Route>
      <Route exact path={`${path}/aboutus`}>
        <About />
      </Route>
      <Route exact path={`${path}/refer`}>
        <Refer />
      </Route>
      <Route exact path={`${path}/aboutplans`}>
        <Plandetails />
      </Route>
    </Switch>
  );
};

export default Dashboard;
