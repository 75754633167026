import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { formatRelative, subDays } from "date-fns";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  updateInvestmentRequest,
  clearUpdateInvestmentSuccess,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PrimaryButton } from "../buttons";

export const EditInvestmentForm = ({
  investment,
  handleClose,
  getUserInvestmentsRequest,
}) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [active, setActive] = React.useState(false);

  const {
    updateInvestmentSuccess,
    updateInvestmentError,
    updateInvestmentLoading,
  } = useSelector((state) => {
    const {
      success: { updateInvestment: updateInvestmentSuccess },
      errors: {
        updateInvestment: updateInvestmentError,
        getInvestment: getInvestmentError,
      },
    } = state.ajaxStatuses;

    const { updateInvestmentLoading, getInvestmentLoading } =
      state.loadingIndicator;

    return {
      updateInvestmentSuccess,
      updateInvestmentError,
      updateInvestmentLoading,
      getInvestmentError,
      getInvestmentLoading,
    };
  });

  useEffect(() => {
    if (updateInvestmentError) {
      toast.error(updateInvestmentError, {
        duration: 3000,
      });
    }
  }, [updateInvestmentError]);

  useEffect(() => {
    if (updateInvestmentSuccess) {
      toast.success(updateInvestmentSuccess, {
        duration: 3000,
      });

      dispatch(getUserInvestmentsRequest(investment.user));

      handleClose();

      dispatch(clearUpdateInvestmentSuccess());
    }
  }, [updateInvestmentSuccess]);

  const formik = useFormik({
    initialValues: {
      expectedReturn: "",
      isActive: Boolean,
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      const formData = prop;
      const id = investment._id;
      const userId = investment.user;

      dispatch(updateInvestmentRequest({ formData, userId, id }));
    },
  });

  useEffect(() => {
    setActive(investment.isActive);
    formik.setValues((values) => ({
      ...values,
      expectedReturn: investment.expectedReturn
        ? investment.expectedReturn
        : "",
      isActive: investment.isActive,
    }));
  }, []);

  const handleActiveChange = (event) => {
    setActive(event.target.checked);
    formik.setFieldValue("isActive", event.target.checked);
  };

  return (
    <div className="container mx-auto max-w-xl mt-20 px-4">
      <h1 className="text-xl text-gray-700">{investment.planName}</h1>
      <h3 className="text-gray-500">
        Growth Rate: {investment.profitProduction}%
      </h3>
      <h3 className="text-gray-500">
        Start Date:{" "}
        {formatRelative(
          subDays(investment && new Date(`${investment.createdAt}`), 1),

          investment && new Date(`${investment.createdAt}`)
        )}
      </h3>

      <div className="mt-5">
        <FormControlLabel
          control={<Checkbox checked={active} onChange={handleActiveChange} />}
          label="Active"
        />
      </div>
      <div className="flex flex-col ">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          Claimable
        </label>
        <input
          type="number"
          name="first-name"
          id="first-name"
          autoComplete="given-name"
          {...formik.getFieldProps("expectedReturn")}
          //   onChange={(e) => handleChange(e.target.value)}
          className={
            error !== ""
              ? "border-red-500 mt-1 p-3 border   block w-full shadow-sm sm:text-sm rounded-md focus:outline-none transition duration-500"
              : "mt-1 p-3 border border-gray-400  focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm rounded-md transition duration-500"
          }
        />
        {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
      </div>

      <div className="shadow rounded p-4 mt-5">
        <div className="flex">
          <h5 className="text-sm text-gray-500 mt-1">$</h5>
          <h2 className="text-3xl text-gray-700">{investment.amount}</h2>
        </div>
      </div>

      <div className="mt-10">
        <PrimaryButton
          text="Update"
          action={formik.handleSubmit}
          loading={updateInvestmentLoading}
        />
      </div>
    </div>
  );
};
