import React, { useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { MdCancel } from "react-icons/md";
import { DeleteUserCard } from "./DeleteUserCard";

export const DeleteUser = ({ user }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userId, setUserId] = useState("");

  const handleUserOpen = (id) => {
    setUserId(id);
    handleOpen();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0"
      >
        <div className=" h-[200px] mx-4 rounded mt-32 bg-gray-50 outline-0 relative">
          <MdCancel
            onClick={handleClose}
            className="cursor-pointer text-red-500 text-2xl absolute right-5 top-5"
          />
          <DeleteUserCard handleClose={handleClose} userId={userId} />
        </div>
      </Modal>

      <div>
        <a
          onClick={() => handleUserOpen(user._id)}
          href="#"
          className="text-red-500 hover:text-red-600 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1 ml-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </a>
      </div>
    </>
  );
};
