import React from "react";
import { format } from "date-fns";

export const InvestmentCard = ({ investment }) => {
  return (
    <div className="p-2 flex flex-col justify-between border rounded-xl">
      <p className="font-body text-sm">
        {investment.planName && investment.planName}
      </p>

      <p className="text-textGray font-body text-sm">
        Profit Production:{" "}
        {investment.profitProduction && investment.profitProduction}%
      </p>

      <p className="text-sm text-textGray  font-body">
        Amount Invested: ${investment.amount && investment.amount}
      </p>

      <p className="text-textGray font-body text-xs">
        End date:{" "}
        {investment.createdAt &&
          format(Date.parse(investment.createdAt), "MM/dd/yyyy")}
      </p>
      <p className=" text-base font-body mt-4 text-primary font-medium">
        Return: ${investment.expectedReturn && investment.expectedReturn}
      </p>
    </div>
  );
};
