import { spawn } from "redux-saga/effects";
import authSagas from "./auth";
import planSagas from "./plan";
import paymentSagas from "./payment";
import investmentSagas from "./investment";
import withdrawSagas from "./withdraw";

export default function* rootSaga() {
  yield spawn(authSagas);
  yield spawn(investmentSagas);
  yield spawn(planSagas);
  yield spawn(paymentSagas);
  yield spawn(withdrawSagas);
}
