import React, { useEffect } from "react";
import {
  getUserInvestmentsRequest,
  getUserRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router";
import { ProfileForm } from "../../../components/Forms/ProfileForm";
import { AdminNav } from "../../../components/Nav/AdminNav";

const EditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    getUserSuccess,
    getUserError,
    getUserLoading,
    user,
    singleUser,
    updateUserSuccess,
    updateUserError,
    updateUserLoading,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
  } = useSelector((state) => {
    const {
      success: {
        getUser: getUserSuccess,
        updateUser: updateUserSuccess,
        getUserInvestments: getUserInvestmentsSuccess,
      },
      errors: {
        getUser: getUserError,
        updateUser: updateUserError,
        getUserInvestments: getUserInvestmentsError,
      },
    } = state.ajaxStatuses;

    const { getUserLoading, updateUserLoading, getUserInvestmentsLoading } =
      state.loadingIndicator;

    const { user, singleUser } = state.userData;
    const { investments } = state.investmentData;

    return {
      getUserSuccess,
      getUserError,
      getUserLoading,
      singleUser,
      user,
      updateUserSuccess,
      updateUserError,
      updateUserLoading,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
    };
  });

  useEffect(() => {
    dispatch(getUserRequest(id));
    dispatch(getUserInvestmentsRequest(id));
  }, [getUserRequest, getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserError) {
      toast.error(getUserError, {
        duration: 3000,
      });
    }
  }, [getUserError]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);
  return (
    <div className="pb-20">
      <div className=" shadow pt-3 pb-2 ">
        <div className="container mx-auto max-w-lg px-4">
          <h1 className="">Edit user</h1>
        </div>
      </div>
      <ProfileForm user={singleUser} loading={getUserLoading} />

      <div className=" max-w-sm mx-auto w-full ">
        <AdminNav />
      </div>
    </div>
  );
};

export default EditUser;
