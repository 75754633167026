import React, { useState } from "react";
import { PrimaryButton } from "../buttons";
import { motion } from "framer-motion";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { UploadForm } from "../Upload";

export const Page2 = ({
  handleClose,
  pages,
  setPages,
  x,
  setX,
  selectedToken,
  amount,
  setAmount,
  formik,
  createPaymentLoading,
}) => {
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(`${value}`);

    toast.success("Copied to clipboard", {
      duration: 2000,
      autoClose: 2000,
      position: "bottom-center",
    });
  };

  return (
    <motion.div
      key={"courseTrack"}
      initial={{ x: 0 }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}
      exit={{ x: x }}
      className="container mx-auto max-w-sm pt-14 px-4 pb-32"
    >
      <div className="flex justify-between px-3">
        <img
          onClick={() => {
            setAmount(0);
            setPages(pages - 1);
            setX(1000);
          }}
          src="/img/icons/arrowleft.svg"
        />
        <p className="text-sm font-body">
          2 <span className="text-fadedGray">of 2</span>
        </p>
        <p
          onClick={() => {
            setAmount(0);
            setPages(pages - 1);
            setX(1000);
            handleClose();
          }}
          className="text-red-500 font-body text-sm cursor-pointer text-right"
        >
          Cancel
        </p>
      </div>

      <div className="pt-4">
        <h2 className="text-xl font-body font-bold "> Confirm Transaction</h2>
      </div>

      <div className="mt-4 bg-primary rounded-lg py-3 text-center flex flex-col gap-y-1">
        <p className="text-xs  font-body font-medium">Amount to Deposit</p>
        <h2 className="text-xl font-body font-medium "> ${amount}</h2>
        <p className="text-base  font-body font-light ">
          worth of {selectedToken.tokenName}
        </p>
      </div>

      <QRCode
        className="mx-auto w-[300px] my-4"
        value={selectedToken.address}
      />

      <div>
        <p className="text-fadedGray text-sm font-body font-light">
          Wallet Address
        </p>
        <div
          onClick={() => copyToClipboard(selectedToken.address)}
          className="flex gap-x-2 "
        >
          <p className=" text-base font-body ">
            {selectedToken.address.slice(0, 6)}*****{" "}
            {selectedToken.address.slice(selectedToken.address.length - 6)}
          </p>
          <img className="" src="/img/icons/copy.svg" />
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-y-2">
        <div className="flex justify-between items-center">
          <p className="font-body text-fadedGray text-sm">Coin</p>
          <div className="flex items-center gap-x-2">
            <img className="" src={selectedToken.logopath} />
            <p className="font-body text-md ">{selectedToken.tokenName}</p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <p className="font-body text-fadedGray text-sm">Network</p>
          <div className="flex items-center gap-x-2">
            <p className="font-body text-md ">{selectedToken.network}</p>
          </div>
        </div>
      </div>

      <form className="max-w-xs mx-auto mt-8">
        <div className="flex flex-col gap-y-4 transition-all duration-500">
          <div className="flex flex-col gap-y-2">
            <label className="text-xs font-body">
              Enter transaction reference
            </label>
            <input
              className="border-b-2 bg-white w-full text-textGray  py-2 px-4 focus:outline-none focus:ring-0 focus:border-gray-100 focus:bg-white"
              type={"text"}
              {...formik.getFieldProps("transactionRef")}
              placeholder="Enter transaction reference"
              required
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col items-center justify-between">
          <p className="text-xs text-textGray font-body">
            Or you can upload transaction image
          </p>
          <UploadForm formik={formik} />
        </div>

        <div className="mt-10 flex flex-col gap-y-6">
          <PrimaryButton
            action={formik.handleSubmit}
            text={"Confirm"}
            loading={createPaymentLoading}
          />
        </div>
      </form>
    </motion.div>
  );
};
