import { CircularProgress } from "@mui/material";
import React from "react";
import { HiArrowRight } from "react-icons/hi";

export const PrimaryButton = ({ text, loading, action, disabled }) => {
  return (
    <div
      onClick={disabled ? () => {} : action}
      className={`${
        disabled ? "bg-[#fcd4353e] text-gray-400" : "bg-primary text-black"
      } cursor-pointer relative font-body flex justify-between items-center text-center py-3 px-5 w-full rounded-2xl text-sm mx-auto transition-all duration-500`}
    >
      {loading ? (
        <CircularProgress size={20} style={{ color: "black" }} />
      ) : (
        text
      )}

      <HiArrowRight className=" font-thin" />
    </div>
  );
};

export const SecondaryButton = ({ text, loading, action, disabled }) => {
  return (
    <div
      onClick={disabled ? () => {} : action}
      className={`${
        disabled ? "bg-[#D5F6EE]" : "bg-white"
      } cursor-pointer border border-black text-black font-body flex justify-between items-center  text-center py-3 px-5  w-full rounded-2xl text-sm mx-auto transition-all duration-500`}
    >
      {loading ? (
        <CircularProgress size={20} style={{ color: "white" }} />
      ) : (
        text
      )}

      <HiArrowRight className=" font-thin" />
    </div>
  );
};
