import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import {
  CREATE_MAIL_REQUEST,
  DELETE_USER_REQUEST,
  GET_LOGINUSER_REQUEST,
  GET_REFERRAL_REQUEST,
  GET_RESETLINK_REQUEST,
  GET_USERS_REQUEST,
  GET_USER_REQUEST,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
} from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  createMailFailure,
  createMailLoading,
  createMailSuccess,
  deleteUserFailure,
  deleteUserLoading,
  deleteUserSuccess,
  getLogUserFailure,
  getLogUserLoading,
  getLogUserSuccess,
  getReferralFailure,
  getReferralLoading,
  getReferralSuccess,
  getResetLinkFailure,
  getResetLinkLoading,
  getResetLinkSuccess,
  getUserFailure,
  getUserLoading,
  getUsersFailure,
  getUsersLoading,
  getUsersSuccess,
  getUserSuccess,
  loginFailure,
  loginLoading,
  loginSuccess,
  registerFailure,
  registerLoading,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordLoading,
  resetPasswordSuccess,
  updatePasswordFailure,
  updatePasswordLoading,
  updatePasswordSuccess,
  updateUserFailure,
  updateUserLoading,
  updateUserSuccess,
} from "../action";

const ajaxDBCalls = {
  register: async ({ formData, refId }) => {
    if (refId) {
      const response = await axios.post(
        `/auth/register?refId=${refId}`,
        formData
      );
      return response;
    } else {
      const response = await axios.post(`/auth/register`, formData);
      return response;
    }
  },
  login: async (formData) => {
    const response = await axios.post(`/auth/login`, formData);
    return response;
  },
  getUsers: async () => {
    const response = await axios.get(`/auth/users`);
    return response;
  },
  getUser: async (id) => {
    const response = await axios.get(`/auth/user/${id}`);
    return response;
  },
  getReferral: async (id) => {
    const response = await axios.get(`/auth/user/${id}`);
    return response;
  },
  updateUser: async ({ formData, id }) => {
    const response = await axios.put(`/auth/user/${id}`, formData);
    return response;
  },
  updatePassword: async ({ formData, token }) => {
    const response = await axios.post(`/auth/user/updatepassword`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  getLogUser: async (token) => {
    const response = await axios.get(`/auth/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  getResetLink: async (formData) => {
    const response = await axios.post(`/auth/forgotpassword`, formData);
    return response;
  },
  resetPassword: async ({ formData, token }) => {
    const response = await axios.put(`/auth/resetpassword/${token}`, formData);
    return response;
  },
  createMail: async ({ formData }) => {
    const response = await axios.post(`/auth/sendmail`, formData);
    return response;
  },
  deleteUser: async ({ id, token }) => {
    const response = await axios.delete(`/auth/user/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
};

function* register({ payload }) {
  try {
    yield put(registerLoading(true));

    const res = yield call(ajaxDBCalls.register, payload);

    yield put(registerSuccess(res.data));

    yield put(registerLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(registerFailure(errorMessage));
    yield put(registerLoading(false));
    yield delay();
    yield put(registerFailure(""));
  }
}
function* login({ payload }) {
  try {
    yield put(loginLoading(true));

    const res = yield call(ajaxDBCalls.login, payload);

    yield put(loginSuccess(res.data));

    yield put(loginLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(loginFailure(errorMessage));
    yield put(loginLoading(false));
    yield delay();
    yield put(loginFailure(""));
  }
}
function* getUsers({ payload }) {
  try {
    yield put(getUsersLoading(true));

    const res = yield call(ajaxDBCalls.getUsers, payload);

    yield put(getUsersSuccess(res.data));

    yield put(getUsersLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUsersFailure(errorMessage));
    yield put(getUsersLoading(false));
    yield delay();
    yield put(getUsersFailure(""));
  }
}
function* getLogUser({ payload }) {
  try {
    yield put(getLogUserLoading(true));

    const res = yield call(ajaxDBCalls.getLogUser, payload);

    yield put(getLogUserSuccess(res.data));

    yield put(getLogUserLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getLogUserFailure(errorMessage));
    yield put(getLogUserLoading(false));
    yield delay();
    yield put(getLogUserFailure(""));
  }
}
function* getUser({ payload }) {
  try {
    yield put(getUserLoading(true));

    const res = yield call(ajaxDBCalls.getUser, payload);

    yield put(getUserSuccess(res.data));

    yield put(getUserLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUserFailure(errorMessage));
    yield put(getUserLoading(false));
    yield delay();
    yield put(getUserFailure(""));
  }
}
function* getReferral({ payload }) {
  try {
    yield put(getReferralLoading(true));

    const res = yield call(ajaxDBCalls.getReferral, payload);

    yield put(getReferralSuccess(res.data));

    yield put(getReferralLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getReferralFailure(errorMessage));
    yield put(getReferralLoading(false));
    yield delay();
    yield put(getReferralFailure(""));
  }
}
function* updateUser({ payload }) {
  try {
    yield put(updateUserLoading(true));

    const res = yield call(ajaxDBCalls.updateUser, payload);

    yield put(updateUserSuccess(res.data));

    yield put(updateUserLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updateUserFailure(errorMessage));
    yield put(updateUserLoading(false));
    yield delay();
    yield put(updateUserFailure(""));
  }
}
function* updatePassword({ payload }) {
  try {
    yield put(updatePasswordLoading(true));

    const res = yield call(ajaxDBCalls.updatePassword, payload);

    yield put(updatePasswordSuccess(res.data));

    yield put(updatePasswordLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updatePasswordFailure(errorMessage));
    yield put(updatePasswordLoading(false));
    yield delay();
    yield put(updatePasswordFailure(""));
  }
}
function* getResetLink({ payload }) {
  try {
    yield put(getResetLinkLoading(true));

    const res = yield call(ajaxDBCalls.getResetLink, payload);

    yield put(getResetLinkSuccess(res.data));

    yield put(getResetLinkLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getResetLinkFailure(errorMessage));
    yield put(getResetLinkLoading(false));
    yield delay();
    yield put(getResetLinkFailure(""));
  }
}
function* resetPassword({ payload }) {
  try {
    yield put(resetPasswordLoading(true));

    const res = yield call(ajaxDBCalls.resetPassword, payload);

    yield put(resetPasswordSuccess(res.data));

    yield put(resetPasswordLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(resetPasswordFailure(errorMessage));
    yield put(resetPasswordLoading(false));
    yield delay();
    yield put(resetPasswordFailure(""));
  }
}

function* createMail({ payload }) {
  try {
    yield put(createMailLoading(true));

    const res = yield call(ajaxDBCalls.createMail, payload);

    yield put(createMailSuccess(res.data));

    yield put(createMailLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createMailFailure(errorMessage));
    yield put(createMailLoading(false));
    yield delay();
    yield put(createMailFailure(""));
  }
}
function* deleteUser({ payload }) {
  try {
    yield put(deleteUserLoading(true));

    const res = yield call(ajaxDBCalls.deleteUser, payload);

    yield put(deleteUserSuccess(res.data));

    yield put(deleteUserLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(deleteUserFailure(errorMessage));
    yield put(deleteUserLoading(false));
    yield delay();
    yield put(deleteUserFailure(""));
  }
}

//Watchers
function* registerWatcher() {
  yield takeLatest(REGISTER_REQUEST, register);
}
function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, login);
}
function* getUsersWatcher() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
}
function* getUserWatcher() {
  yield takeLatest(GET_USER_REQUEST, getUser);
}
function* getReferralWatcher() {
  yield takeLatest(GET_REFERRAL_REQUEST, getReferral);
}
function* getLogUserWatcher() {
  yield takeLatest(GET_LOGINUSER_REQUEST, getLogUser);
}
function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
}
function* updatePasswordWatcher() {
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
}
function* getResetLinkWatcher() {
  yield takeLatest(GET_RESETLINK_REQUEST, getResetLink);
}
function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
}
function* createMailWatcher() {
  yield takeLatest(CREATE_MAIL_REQUEST, createMail);
}
function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}

export default function* authSagas() {
  yield spawn(registerWatcher);
  yield spawn(loginWatcher);
  yield spawn(getUsersWatcher);
  yield spawn(getUserWatcher);
  yield spawn(getReferralWatcher);
  yield spawn(getLogUserWatcher);
  yield spawn(updateUserWatcher);
  yield spawn(updatePasswordWatcher);
  yield spawn(getResetLinkWatcher);
  yield spawn(resetPasswordWatcher);
  yield spawn(createMailWatcher);
  yield spawn(deleteUserWatcher);
}
