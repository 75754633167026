import React, { useEffect } from "react";
import { GoBack } from "../../../components/Layout/GoBack";
import { BottomNav } from "../../../components/Nav/BottomNav";
import { SideNav } from "../../../components/Nav/SideNav";
import { useDispatch, useSelector } from "react-redux";
import { getLogUserRequest, logout } from "../../../redux/action";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const Setting = () => {
  const dispatch = useDispatch();

  const { user, getLogUserError, token, getLogUserLoading } = useSelector(
    (state) => {
      const {
        errors: { getPlans: getPlansError, getLogUser: getLogUserError },
      } = state.ajaxStatuses;

      const { getLogUserLoading } = state.loadingIndicator;

      const { user, token } = state.userData;

      return {
        user,
        getLogUserError,
        token,
        getLogUserLoading,
      };
    }
  );

  useEffect(() => {
    if (getLogUserError) {
      toast.error(getLogUserError, {
        duration: 3000,
      });
    }
  }, [getLogUserError]);

  useEffect(() => {
    dispatch(getLogUserRequest(token));
  }, [getLogUserRequest]);

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div>
      <div className="container px-4 py-8 max-w-sm mx-auto relative h-screen overflow-y-scroll">
        {/* <div className="flex flex-row justify-between">
          <div></div>
          <p
            onClick={logOut}
            className="text-red-500 font-body text-sm cursor-pointer text-right"
          >
            Logout
          </p>
        </div> */}

        {getLogUserLoading ? (
          <div className="flex items-center justify-center py-20">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <div className="mx-auto px-4 mt-4">
            <img className="w-32 mx-auto" src="/img/new/logo/logo.png" />

            <div className="text-center font-body mt-4">
              <div className="flex flex-col gap-y-0">
                <h5 className="text-md text-gray-500">{user.userName}</h5>
                <h5 className="text-sm">{user.email}</h5>
                {user.walletAddress && (
                  <h5 className="text-md">
                    {" "}
                    {user.walletAddress.slice(0, 3)}****
                    {user.walletAddress.slice(user.walletAddress.length - 3)}
                  </h5>
                )}
              </div>
              <div className="flex items-center mt-2 gap-x-4 text-center justify-center">
                <div>
                  <p className="text-xs text-gray-500">Balance</p>
                  <h1 className="text-xl">${user.balance}</h1>
                </div>
                {/* <div>
                <p className="text-xs text-gray-500">Ref bonus</p>
                <h1 className="text-xl">${user.referralBonus}</h1>
              </div> */}
                <div className="text-center">
                  <p className="text-xs text-gray-500">Ref count</p>
                  <h1 className="text-xl">{user.referralCount}</h1>
                </div>
              </div>
            </div>
          </div>
        )}

        <SideNav />
      </div>

      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Setting;
