import {
  CREATE_PLAN_LOADING,
  CREATE_PAYMENT_LOADING,
  GET_PLANS_LOADING,
  GET_PAYMENTS_LOADING,
  GET_USERS_LOADING,
  GET_USER_INVESTMENTS_LOADING,
  GET_USER_LOADING,
  LOGIN_LOADING,
  REGISTER_LOADING,
  CREATE_INVESTMENT_LOADING,
  GET_INVESTMENT_LOADING,
  UPDATE_INVESTMENT_LOADING,
  UPDATE_USER_LOADING,
  UPDATE_PASSWORD_LOADING,
  CREATE_WITHDRAW_LOADING,
  GET_WITHDRAWS_LOADING,
  GET_LOGINUSER_LOADING,
  GET_RESETLINK_LOADING,
  RESET_PASSWORD_LOADING,
  CREATE_MAIL_LOADING,
  DELETE_USER_LOADING,
  CLAIM_INVESTMENT_LOADING,
  GET_USER_PAYMENTS_LOADING,
  UPDATE_PAYMENT_LOADING,
  UPDATE_INVESTMENTS_LOADING,
} from "../types";

const initialState = {
  registerLoading: false,
  loginLoading: false,
  createPlanLoading: false,
  createPaymentLoading: false,
  createWithdrawLoading: false,
  getPlansLoading: true,
  getPaymentsLoading: true,
  getUserPaymentsLoading: true,
  getWithdrawsLoading: true,
  getUsersLoading: true,
  getUserLoading: true,
  getUserInvestmentsLoading: true,
  getInvestmentLoading: true,
  updateUserLoading: false,
  updatePasswordLoading: false,
  createInvestmentLoading: false,
  claimInvestmentLoading: false,
  updateInvestmentLoading: false,
  updateInvestmentsLoading: false,
  getLogUserLoading: true,
  getResetLinkLoading: false,
  resetPasswordLoading: false,
  createMailLoading: false,
  deleteUserLoading: false,
  updatePaymentLoading: false,
};

const loadingIndicator = (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_LOADING:
      return {
        ...state,
        registerLoading: payload,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: payload,
      };
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: payload,
      };
    case UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        updatePasswordLoading: payload,
      };
    case CREATE_PLAN_LOADING:
      return {
        ...state,
        createPlanLoading: payload,
      };
    case CREATE_PAYMENT_LOADING:
      return {
        ...state,
        createPaymentLoading: payload,
      };
    case CREATE_WITHDRAW_LOADING:
      return {
        ...state,
        createWithdrawLoading: payload,
      };
    case GET_LOGINUSER_LOADING:
      return {
        ...state,
        getLogUserLoading: payload,
      };
    case GET_PLANS_LOADING:
      return {
        ...state,
        getPlansLoading: payload,
      };
    case GET_PAYMENTS_LOADING:
      return {
        ...state,
        getPaymentsLoading: payload,
      };
    case GET_USER_PAYMENTS_LOADING:
      return {
        ...state,
        getUserPaymentsLoading: payload,
      };
    case GET_WITHDRAWS_LOADING:
      return {
        ...state,
        getWithdrawsLoading: payload,
      };
    case GET_USERS_LOADING:
      return {
        ...state,
        getUsersLoading: payload,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        getUserLoading: payload,
      };

    case GET_USER_INVESTMENTS_LOADING:
      return {
        ...state,
        getUserInvestmentsLoading: payload,
      };

    case GET_INVESTMENT_LOADING:
      return {
        ...state,
        getInvestmentLoading: payload,
      };

    case CREATE_INVESTMENT_LOADING:
      return {
        ...state,
        createInvestmentLoading: payload,
      };

    case CLAIM_INVESTMENT_LOADING:
      return {
        ...state,
        claimInvestmentLoading: payload,
      };

    case UPDATE_INVESTMENT_LOADING:
      return {
        ...state,
        updateInvestmentLoading: payload,
      };

    case UPDATE_INVESTMENTS_LOADING:
      return {
        ...state,
        updateInvestmentsLoading: payload,
      };

    case UPDATE_PAYMENT_LOADING:
      return {
        ...state,
        updatePaymentLoading: payload,
      };

    case GET_RESETLINK_LOADING:
      return {
        ...state,
        getResetLinkLoading: payload,
      };

    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordLoading: payload,
      };

    case CREATE_MAIL_LOADING:
      return {
        ...state,
        createMailLoading: payload,
      };

    case DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: payload,
      };
    default:
      return state;
  }
};

export default loadingIndicator;
