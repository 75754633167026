import React from "react";
import { format } from "date-fns";

export const TransactionCard = ({ payment }) => {
  return (
    <div className="p-2 flex justify-between border rounded-xl">
      <div className="flex flex-col justify-center gap-y-1">
        <div className="flex gap-x-2">
          {payment.type === "Deposite" ? (
            <img src="/img/icons/add.svg"></img>
          ) : (
            <img src="/img/icons/withdraw1.svg" />
          )}
          <div>
            <p className="font-body text-sm">{payment.type && payment.type}</p>
            <p className="text-fadedGray font-body text-xs">
              {payment.createdAt &&
                format(Date.parse(payment.createdAt), "MM/dd/yyyy")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-y-1">
        <p
          className={`font-body text-sm ${
            payment.type === "Deposite" ? "text-black" : "text-red-500"
          } `}
        >
          ${payment.amount && payment.amount}
        </p>
        <p className="text-fadedGray font-body text-xs">
          {payment.status && payment.status}
        </p>
      </div>
    </div>
  );
};
