import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { PrimaryButton } from "../buttons";
import { InvestForm } from "../Forms/InvestForm";
import { HiArrowRight } from "react-icons/hi";

export const Invest = ({ fromWallet, isHome }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 transition duration-500 "
      >
        <InvestForm
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Modal>

      {fromWallet ? (
        <div
          onClick={handleOpen}
          className={`font-body font-medium text-xs bg-[#2878f160] text-black  text-center  flex  items-center justify-center px-7 py-2 rounded-xl cursor-pointer`}
        >
          <p className="font-body text-[#2878f1]">Invest</p>
        </div>
      ) : (
        <div
          onClick={handleOpen}
          className="bg-primary rounded-xl flex justify-between items-center text-center text-black text-sm mt-3 py-2 px-5 hover:border hover:border-primary hover:text-primary hover:bg-white transition-all duration-500 "
        >
          <p>Invest</p>

          <HiArrowRight className=" font-thin" />
        </div>
      )}
    </>
  );
};
