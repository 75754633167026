import React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { OnboardingNav } from "../../../components/Nav/BottomNav";
import { Onboarding } from "../../../components/Sections/Onboarding";

const Home1 = () => {
  return (
    <div>
      <Onboarding
        linkto={"/signup"}
        heading={"Earn from Energy"}
        body={
          "NextEraEarning is America's largest capital investors in infrastructure, with between $50 and $55 billion in new infrastructure investments planned through 2023, we're helping ensure that the next energy to power our dreams will be American energy.."
        }
        imgurl={"/img/new/background/bit.jpg"}
        next={"/home2"}
      />
    </div>
  );
};

export default Home1;
