import { useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { claimInvestmentRequest } from "../../redux/action";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { FaTimes } from "react-icons/fa";
import { EditInvestmentForm } from "../Forms/EditInvestmentForm";

export const AdminInvestmentCard = ({ data, getUserInvestmentsRequest }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 "
      >
        <div className=" h-full w-full  rounded  bg-white outline-0 relative transition duration-500">
          <FaTimes
            onClick={handleClose}
            className="cursor-pointer  text-2xl absolute right-5 top-5"
          />
          <EditInvestmentForm
            investment={data}
            handleClose={handleClose}
            getUserInvestmentsRequest={getUserInvestmentsRequest}
          />
        </div>
      </Modal>
      <div className="shadow flex h-[140px] rounded relative">
        {/* <div className="m-2 ">
          <img
            className="h-full rounded"
            src={
              data.planName === "Agrox"
                ? "/img/card/farm.jpg"
                : data.planName === "Forex"
                ? "/img/card/trading.jpg"
                : "/img/card/house.jpg"
            }
          ></img>
        </div> */}
        <div className="m-2 flex flex-col ">
          <h3 className="text-md font-bold font-heading">
            {data && data.planName}
          </h3>
          <h3 className="text-sm">Amount: ${data && data.amount}</h3>
          <h2 className="text-sm">
            Start date:{" "}
            {data.createdAt && format(Date.parse(data.createdAt), "MM/dd/yyyy")}
          </h2>
          <h3 className="text-sm">Claimable: ${data && data.expectedReturn}</h3>
          <div className="bg-green-500 text-dark text-xs text-center w-16 rounded-full py-1">
            {data && data.profitProduction}%
          </div>
        </div>
        <div
          onClick={handleOpen}
          className="absolute bottom-2 right-4 ml-auto w-16 bg-primary text-dark flex items-center justify-center text-sm h-6 cursor-pointer rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
          Edit
        </div>
      </div>
    </>
  );
};
