import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const GoBack = () => {
  const history = useHistory();

  return (
    <div onClick={history.goBack} className="cursor-pointer flex gap-x-3">
      <img className="cursor-pointer" src="/img/icons/arrowleft.svg" />
    </div>
  );
};
