import React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons";
import { OnboardingNav } from "../../../components/Nav/BottomNav";
import { Onboarding } from "../../../components/Sections/Onboarding";

const Home3 = () => {
  return (
    <div>
      <Onboarding
        linkto={"/signup"}
        heading={"Save money"}
        body={
          "We help you meet your savings target monthly and our emergency plansenable you save for multiple purposes"
        }
        imgurl={"/img/new/background/home3.jpg"}
        next={"/signup"}
      />
    </div>
  );
};

export default Home3;
