import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import {
  CREATE_PAYMENT_REQUEST,
  GET_PAYMENTS_REQUEST,
  GET_USER_PAYMENTS_REQUEST,
  UPDATE_PAYMENT_REQUEST,
} from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  createPaymentFailure,
  createPaymentLoading,
  createPaymentSuccess,
  getPaymentsFailure,
  getPaymentsLoading,
  getPaymentsSuccess,
  getUserPaymentsFailure,
  getUserPaymentsLoading,
  getUserPaymentsSuccess,
  updatePaymentFailure,
  updatePaymentLoading,
  updatePaymentSuccess,
} from "../action";

const ajaxDBCalls = {
  createPayment: async (formData) => {
    const response = await axios.post(`/fund/payment/create`, formData);
    return response;
  },
  getUserPayments: async (id) => {
    const response = await axios.get(`/fund/user/${id}`);
    return response;
  },
  getPayments: async () => {
    const response = await axios.get(`/fund`);
    return response;
  },
  updatePayment: async ({ formData, id }) => {
    const response = await axios.put(`/fund/payment/${id}`, formData);
    return response;
  },
};

function* createPayment({ payload }) {
  try {
    yield put(createPaymentLoading(true));

    const res = yield call(ajaxDBCalls.createPayment, payload);

    yield put(createPaymentSuccess(res.data));

    yield put(createPaymentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createPaymentFailure(errorMessage));
    yield put(createPaymentLoading(false));
    yield delay();
    yield put(createPaymentFailure(""));
  }
}
function* getPayments({ payload }) {
  try {
    yield put(getPaymentsLoading(true));

    const res = yield call(ajaxDBCalls.getPayments, payload);

    yield put(getPaymentsSuccess(res.data));

    yield put(getPaymentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getPaymentsFailure(errorMessage));
    yield put(getPaymentsLoading(false));
    yield delay();
    yield put(getPaymentsFailure(""));
  }
}
function* updatePayment({ payload }) {
  try {
    yield put(updatePaymentLoading(true));

    const res = yield call(ajaxDBCalls.updatePayment, payload);

    yield put(updatePaymentSuccess(res.data));

    yield put(updatePaymentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updatePaymentFailure(errorMessage));
    yield put(updatePaymentLoading(false));
    yield delay();
    yield put(updatePaymentFailure(""));
  }
}

function* getUserPayments({ payload }) {
  try {
    yield put(getUserPaymentsLoading(true));

    const res = yield call(ajaxDBCalls.getUserPayments, payload);

    yield put(getUserPaymentsSuccess(res.data));

    yield put(getUserPaymentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUserPaymentsFailure(errorMessage));
    yield put(getUserPaymentsLoading(false));
    yield delay();
    yield put(getUserPaymentsFailure(""));
  }
}

//Watchers
function* createPaymentWatcher() {
  yield takeLatest(CREATE_PAYMENT_REQUEST, createPayment);
}
function* getPaymentsWatcher() {
  yield takeLatest(GET_PAYMENTS_REQUEST, getPayments);
}
function* getUserPaymentsWatcher() {
  yield takeLatest(GET_USER_PAYMENTS_REQUEST, getUserPayments);
}
function* updatePaymentWatcher() {
  yield takeLatest(UPDATE_PAYMENT_REQUEST, updatePayment);
}

export default function* paymentSagas() {
  yield spawn(createPaymentWatcher);
  yield spawn(getPaymentsWatcher);
  yield spawn(getUserPaymentsWatcher);
  yield spawn(updatePaymentWatcher);
}
